import { Bech32Address } from '@keplr-wallet/cosmos'

import { ChainInfoID } from 'types/enums/wallet'

export const CHAINS: ChainInfos = {
  [ChainInfoID.Mars1]: {
    rpc: 'https://rpc.marsprotocol.io',
    rest: 'https://rest.marsprotocol.io',
    explorer: 'https://www.mintscan.io/mars-protocol',
    explorerName: 'Mintscan',
    chainId: ChainInfoID.Mars1,
    name: 'Mars Hub',
    bech32Config: Bech32Address.defaultBech32Config('mars'),
    gasPrice: '0.01umars',
    defaultCurrency: {
      coinDenom: 'MARS',
      coinMinimalDenom: 'umars',
      coinDecimals: 6,
      coinGeckoId: 'marsprotocol',
      gasPriceStep: {
        low: 0.01,
        average: 0.01,
        high: 0.01,
      },
    },
    features: ['ibc-transfer', 'ibc-go'],
  },
}
