import { CHAINS } from 'constants/chains'
import { ChainInfoID } from 'types/enums/wallet'

export const ENV: EnvironmentVariables = {
  ADDRESS_VESTING:
    process.env.NEXT_PUBLIC_VESTING ||
    'mars14hj2tavq8fpesdwxxcu44rty3hh90vhujrvcmstl4zr3txmfvw9smxjtde',
  ADDRESS_INCENTIVES: 'mars1krxwf5e308jmclyhfd9u92kp369l083wvxkp6n',
  WALLET_CONNECT_ID: process.env.NEXT_PUBLIC_WALLET_CONNECT_ID || '',
  CHAIN: CHAINS[ChainInfoID.Mars1],
  CS_URL: 'https://status.marsprotocol.io/cs',
  TS_URL: 'https://status.marsprotocol.io/ts',
  TOKENS_URL: 'https://api.astroport.fi/api/tokens?chainId=neutron-1',
  MARS_DENOM_NEUTRON: 'ibc/9598CDEB7C6DB7FC21E746C8E0250B30CD5154F39CA111A9D4948A4362F638BD',
}
