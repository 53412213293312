import { ASSETS } from 'constants/assets'

export function getAssetByDenom(denom: string): Asset | undefined {
  return ASSETS.find((asset) => asset.denom === denom)
}

export function getAssetBySymbol(symbol: string) {
  return ASSETS.find((asset) => asset.symbol === symbol)
}

export function getBaseAsset() {
  return ASSETS.find((asset) => asset.denom === 'umars')!
}

export function getDisplayCurrencies() {
  return ASSETS.filter((asset) => asset.isDisplayCurrency)
}

export function getAllAssets(): Asset[] {
  return ASSETS
}

export function findCoinByDenom(denom: string, coins: BigNumberCoin[]) {
  return coins.find((coin) => coin.denom === denom)
}
