export const ASSETS: Asset[] = [
  {
    symbol: 'MARS',
    name: 'Mars',
    id: 'MARS',
    denom: 'umars',
    color: '#a03b45',
    logo: '/images/tokens/mars.svg',
    decimals: 6,
    isEnabled: true,
    isDisplayCurrency: true,
  },
  {
    symbol: '$',
    name: 'US Dollar',
    id: 'USD',
    denom: 'usd',
    color: '',
    logo: '',
    decimals: 2,
    isEnabled: false,
    isDisplayCurrency: true,
  },
]

export const MARS = ASSETS[0]
