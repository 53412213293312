export const defaultFee: StdFee = {
  amount: [
    {
      denom: 'umars',
      amount: '100000',
    },
  ],
  gas: '10000000',
}

export const SECONDS_IN_A_YEAR = 31540000
