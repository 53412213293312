import { MsgExecuteContract } from '@delphi-labs/shuttle-react'
import BigNumber from 'bignumber.js'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import { GetState, SetState } from 'zustand'

import { ENV } from 'constants/env'
import { Store } from 'store'
import { generateErrorMessage } from 'utils/broadcast'
import { defaultFee } from 'utils/constants'

function generateExecutionMessage(
  sender: string | undefined = '',
  contract: string,
  msg: Record<string, any>,
  funds: Coin[],
) {
  return new MsgExecuteContract({
    sender,
    contract,
    msg,
    funds,
  })
}

export default function createBroadcastSlice(
  set: SetState<Store>,
  get: GetState<Store>,
): BroadcastSlice {
  const handleResponseMessages = (props: HandleResponseProps) => {
    const { id, response, action, message } = props
    if (!response) return

    if (response.error || response.result?.response.code !== 0) {
      set({
        toast: {
          id,
          message: generateErrorMessage(response),
          isError: true,
          hash: response.result?.hash,
        },
      })
      return
    }

    const toast: ToastResponse = {
      id,
      isError: false,
      hash: response?.result?.hash,
      timestamp: moment().unix(),
      address: get().address ?? '',
    }

    if (message) {
      toast.message = message
      set({ toast })
      return
    }

    set({ toast })
    return
  }

  const getEstimatedFee = async (messages: MsgExecuteContract[]) => {
    if (!get().client) {
      return defaultFee
    }
    try {
      const simulateResult = await get().client?.simulate({
        messages,
        wallet: get().client?.connectedWallet,
      })

      if (simulateResult) {
        const { success } = simulateResult
        if (success) {
          const fee = simulateResult.fee
          return {
            amount: fee ? fee.amount : [],
            gas: new BigNumber(fee ? fee.gas : 0).toFixed(0),
          }
        }
      }

      throw 'Simulation failed'
    } catch (ex) {
      return defaultFee
    }
  }

  return {
    toast: null,
    withdraw: async () => {
      const msg = {
        withdraw: {},
      }

      const response = get().executeMsg({
        messages: [generateExecutionMessage(get().address, ENV.ADDRESS_VESTING, msg, [])],
      })

      get().setToast({
        response,
        options: {
          action: 'withdraw',
        },
      })

      return response.then((response) => !!response.result)
    },
    setToast: (toast: ToastObject) => {
      const id = moment().unix()
      set({
        toast: {
          id,
          promise: toast.response,
        },
      })

      toast.response.then((response) => {
        handleResponseMessages({
          id,
          response,
          ...toast.options,
        })
      })
    },
    executeMsg: async (options: { messages: MsgExecuteContract[] }): Promise<BroadcastResult> => {
      try {
        const client = get().client
        if (!client) return { error: 'no client detected' }
        const fee = await getEstimatedFee(options.messages)
        const broadcastOptions = {
          messages: options.messages,
          feeAmount: fee.amount[0].amount,
          gasLimit: fee.gas,
          memo: undefined,
          wallet: client.connectedWallet,
          mobile: isMobile,
        }
        const result = await client.broadcast(broadcastOptions)
        if (result.hash) {
          return { result }
        }

        return {
          result: undefined,
          error: 'Transaction failed',
        }
      } catch (e: unknown) {
        const error = typeof e === 'string' ? e : 'Transaction failed'
        return { result: undefined, error }
      }
    },
  }
}
